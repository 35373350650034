import React from "react"
import PropTypes from "prop-types"

import Footer from "./footer"

import "../styles/components/layout.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, ...props }) => {
  return (
    <>
      <main>{children}</main>
      <Footer {...props} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
