import React from "react"

import styles from "../styles/components/footer.module.scss"

import IconLogo from "../images/icon-logo.svg"
import IconEmail from "../images/icon-email.svg"
import IconFacebook from "../images/icon-facebook.svg"

const Footer = ({ showConsultations, showInstall, showTakedown }) => (
  <footer className={styles.container}>
    <div className={styles.logo}>
      <IconLogo />
    </div>
    <p className={styles.logoText}>Pelham Lights</p>
    <p>Pelham, NY 10803</p>
    <div className={styles.scheduleContainer}>
      {showConsultations && (
        <>
          <p className={styles.note}>Need a consultation?</p>
          <a
            href="https://meetings.hubspot.com/pelhamlights/consultation"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schedule your consultation
          </a>
        </>
      )}
      {showInstall && (
        <>
          <p className={styles.note}>Already had a consultation?</p>
          <a
            href="https://meetings.hubspot.com/pelhamlights/installation"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schedule your lights installation
          </a>
        </>
      )}
      {showTakedown && (
        <>
          <p className={styles.note}>Need to schedule your lights takedown?</p>
          <a
            href="https://meetings.hubspot.com/pelhamlights/takedown"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schedule your takedown
          </a>
        </>
      )}
    </div>
    <a href="mailto:info@pelhamlights.com">
      <IconEmail />
      info@pelhamlights.com
    </a>
    <a
      href="https://www.facebook.com/PelhamLights"
      target="_blank"
      rel="noreferrer noopener"
    >
      <IconFacebook />
      facebook.com/PelhamLights
    </a>
  </footer>
)

export default Footer
